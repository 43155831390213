// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function TiktokIconsvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 150 150"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M106.7 0H81.42v102.17c0 12.17-9.72 22.17-21.82 22.17s-21.82-10-21.82-22.17S47.29 80.43 58.95 80V54.35c-25.71.43-46.45 21.52-46.45 47.83s21.17 47.83 47.32 47.83 47.32-21.52 47.32-47.83V49.79c9.51 6.96 21.17 11.09 33.49 11.3V35.44c-19.01-.65-33.92-16.3-33.92-35.43z"
        }
      ></path>
    </svg>
  );
}

export default TiktokIconsvgIcon;
/* prettier-ignore-end */
