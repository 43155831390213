// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function InstagramIconsvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 150 150"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <defs>
        <clipPath id={"43IqNkLiga"}>
          <path fill={"none"} d={"M0 0h150v150H0z"}></path>
        </clipPath>
      </defs>

      <g clipPath={"url(#43IqNkLiga)"}>
        <path
          d={
            "M75 13.51c20.04 0 22.41.09 30.29.44 7.32.32 11.28 1.55 13.92 2.58 3.49 1.35 6.01 2.99 8.61 5.6 2.64 2.64 4.25 5.13 5.6 8.61 1.02 2.64 2.26 6.62 2.58 13.92.35 7.91.44 10.28.44 30.29s-.09 22.41-.44 30.29c-.32 7.32-1.55 11.28-2.58 13.92-1.35 3.49-2.99 6.01-5.6 8.61-2.64 2.64-5.13 4.25-8.61 5.59-2.64 1.03-6.62 2.26-13.92 2.58-7.91.35-10.28.44-30.29.44s-22.41-.09-30.29-.44c-7.32-.32-11.28-1.55-13.92-2.58-3.49-1.35-6.01-2.99-8.61-5.59-2.64-2.64-4.25-5.13-5.6-8.61-1.03-2.64-2.26-6.62-2.58-13.92-.35-7.91-.44-10.28-.44-30.29s.09-22.41.44-30.29c.32-7.32 1.55-11.28 2.58-13.92 1.35-3.49 2.99-6.01 5.6-8.61 2.64-2.64 5.13-4.25 8.61-5.6 2.64-1.03 6.62-2.26 13.92-2.58 7.88-.35 10.25-.44 30.29-.44zM75 0C54.64 0 52.09.09 44.09.44 36.12.79 30.64 2.08 25.9 3.93c-4.95 1.93-9.14 4.48-13.3 8.67-4.19 4.16-6.74 8.35-8.67 13.27C2.08 30.65.8 36.09.44 44.06.09 52.09 0 54.64 0 75s.09 22.91.44 30.91c.35 7.97 1.64 13.45 3.49 18.19 1.93 4.95 4.48 9.14 8.67 13.3 4.16 4.16 8.35 6.74 13.27 8.64 4.78 1.85 10.22 3.13 18.19 3.49 8 .35 10.55.44 30.91.44s22.91-.09 30.91-.44c7.97-.35 13.45-1.64 18.19-3.49 4.92-1.9 9.11-4.48 13.27-8.64s6.74-8.35 8.64-13.27c1.85-4.78 3.14-10.22 3.49-18.19.35-8 .44-10.55.44-30.91s-.09-22.91-.44-30.91c-.35-7.97-1.64-13.45-3.49-18.19-1.84-4.98-4.39-9.17-8.58-13.33-4.16-4.16-8.35-6.74-13.27-8.64-4.78-1.85-10.22-3.13-18.19-3.49C97.91.09 95.36 0 75 0z"
          }
        ></path>

        <path
          d={
            "M75 36.47c-21.27 0-38.53 17.26-38.53 38.53S53.73 113.53 75 113.53 113.53 96.27 113.53 75 96.27 36.47 75 36.47zm0 63.52C61.2 99.99 50.01 88.8 50.01 75S61.2 50.01 75 50.01 99.99 61.2 99.99 75 88.8 99.99 75 99.99zm49.04-65.04c0 4.98-4.04 8.99-8.99 8.99s-8.99-4.04-8.99-8.99 4.04-8.99 8.99-8.99 8.99 4.04 8.99 8.99z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default InstagramIconsvgIcon;
/* prettier-ignore-end */
