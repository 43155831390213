// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function IconCrosssvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      viewBox={"0 0 15 15"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g
        fill={"currentColor"}
        stroke={"none"}
        strokeWidth={"1"}
        fillRule={"evenodd"}
      >
        <rect
          transform={"rotate(45 7.425 7.425)"}
          x={"-2.075"}
          y={"6.425"}
          width={"19"}
          height={"2"}
          rx={"1"}
        ></rect>

        <rect
          transform={"scale(-1 1) rotate(45 0 -10.5)"}
          x={"-2.075"}
          y={"6.425"}
          width={"19"}
          height={"2"}
          rx={"1"}
        ></rect>
      </g>
    </svg>
  );
}

export default IconCrosssvgIcon;
/* prettier-ignore-end */
