// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function FacebookIconsvg2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 150 150"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <defs>
        <clipPath id={"_UTlF10SOa"}>
          <path fill={"none"} d={"M0 0h150v150H0z"}></path>
        </clipPath>
      </defs>

      <g clipPath={"url(#_UTlF10SOa)"}>
        <path
          d={
            "M150 75c0-41.42-33.58-75-75-75S0 33.58 0 75c0 37.43 27.43 68.46 63.28 74.09V96.68H44.24V75h19.04V58.48c0-18.8 11.2-29.18 28.33-29.18 8.2 0 16.79 1.46 16.79 1.46v18.46h-9.46c-9.32 0-12.22 5.78-12.22 11.72V75h20.8l-3.33 21.68H86.71v52.41c35.86-5.63 63.28-36.66 63.28-74.09z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default FacebookIconsvg2Icon;
/* prettier-ignore-end */
